import React from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';

import DefaultAvatar from '~/assets/user-default.svg';

import routes from '~/constants/routes';
import getUserFullName from '~/utils/getUserFullName';

import { NotificationsCircle } from '../styles/menuItemDesign';

import type { IUser } from '@learned/types';
import type { I18n } from '@lingui/core';

const AvatarWrapper = styled.div`
  display: relative;
`;

const NotificationDot = styled(NotificationsCircle)`
  display: absolute;
  top: 0;
  right: 0;
`;

const BadgeAvatar = styled.div<{
  avatarUrl: string;
}>`
  width: 32px;
  height: 32px;
  outline-color: transparent;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url("${props.avatarUrl}")`};

  &:focus {
    outline-color: transparent;
  }
`;

const accountMenuStructure = ({
  user,
  isUserHasAdminRole,
  isModuleIntegrationsEnabled,
  logout,
  onOpenNotebook,
  numberOfActiveInvites,
  numberOfUnreadNotifications,
  isModuleReviewsEnabled,
  isModuleMeetingsEnabled,
}: {
  user: IUser;
  isUserHasAdminRole: boolean;
  isModuleIntegrationsEnabled: boolean;
  logout: () => void;
  onOpenNotebook: () => void;
  numberOfActiveInvites: number;
  numberOfUnreadNotifications: number;
  onReadAllNotifications: () => void;
  isModuleReviewsEnabled: boolean;
  isModuleMeetingsEnabled: boolean;
}) => {
  return [
    {
      title: (i18n: I18n) => i18n._(t`Profile & settings`),
      iconNode: (
        <AvatarWrapper>
          {numberOfUnreadNotifications > 0 ? <NotificationDot /> : null}
          <BadgeAvatar avatarUrl={user.avatarUrl || DefaultAvatar} />
        </AvatarWrapper>
      ),
      key: 'account',
      userFullName: getUserFullName(user),
      userEmail: user?.email || '',
      _children: [
        {
          title: (i18n: I18n) => i18n._(t`Profile settings`),
          url: routes.PROFILE_SETTINGS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.SETTINGS,
          key: 'profile-settings',
        },
        {
          title: (i18n: I18n) =>
            i18n._(
              t`Notifications ${
                numberOfUnreadNotifications > 0 ? `(${numberOfUnreadNotifications})` : ''
              }`,
            ),
          url: routes.NOTIFICATIONS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.NOTIFICATIONS,
          numberOfNotifications: numberOfUnreadNotifications,
          key: 'notifications',
        },
        {
          title: (i18n: I18n) => i18n._(t`Invites`),
          url: routes.INVITES.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.INVITES,
          numberOfNotifications: numberOfActiveInvites || 0,
          key: 'invites',
        },
        (isModuleMeetingsEnabled || isModuleReviewsEnabled) && {
          title: (i18n: I18n) => i18n._(t`Next steps`),
          url: routes.NEXT_STEPS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.NEXT_STEPS,
          aliases: [routes.NEXT_STEPS.build({ role: ROLES.USER })],
          key: 'next-steps',
        },
        {
          title: (i18n: I18n) => i18n._(t`Notebook`),
          onClick: onOpenNotebook,
          role: ROLES.USER,
          isDivider: true,
          icon: ICONS.NOTEBOOK,
          key: 'notebook',
        },
        ...(isUserHasAdminRole
          ? [
              {
                title: (i18n: I18n) => i18n._(t`Members`),
                url: routes.MEMBERS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.MEMBERS,
                key: 'members',
              },
              {
                title: (i18n: I18n) => i18n._(t`Teams`),
                url: routes.TEAMS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.TEAM,
                key: 'teams',
              },
              {
                title: (i18n: I18n) => i18n._(t`Company settings`),
                url: routes.SETTINGS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.COMPANY_SETTINGS,
                key: 'company-settings',
              },
              {
                title: (i18n: I18n) => i18n._(t`Log`),
                url: routes.LOGS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.LOG,
                key: 'log',
              },
              isModuleIntegrationsEnabled && {
                title: (i18n: I18n) => i18n._(t`Integrations`),
                url: routes.SETTINGS_INTEGRATIONS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.INTEGRATION,
                key: 'integrations',
              },
              isModuleIntegrationsEnabled && {
                title: (i18n: I18n) => i18n._(t`Integrations (new)`),
                url: routes.SETTINGS_INTEGRATIONS_HRIS.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                icon: ICONS.INTEGRATION,
                key: 'integrations-new',
              },
              {
                title: (i18n: I18n) => i18n._(t`Plan`),
                url: routes.SETTINGS_MODULES.build({ role: ROLES.ADMIN }),
                role: ROLES.ADMIN,
                isDivider: true,
                icon: ICONS.PLAN,
                key: 'plan',
              },
            ].filter((c) => c)
          : []),
        // @ts-ignore
        user.isSuperAdmin && {
          title: (i18n: I18n) => i18n._(t`Super Admin`),
          url: routes.SUPERADMIN_COMPANY_DASHBOARD.url,
          role: ROLES.ADMIN,
          icon: ICONS.SUPERADMIN,
          key: 'super-admin',
        },
        {
          title: (i18n: I18n) => i18n._(t`Help center`),
          href: 'https://intercom.help/learned',
          role: ROLES.USER,
          icon: ICONS.HELP,
          key: 'help-center',
        },
        {
          title: (i18n: I18n) => i18n._(t`Product roadmap`),
          url: routes.ROADMAP.build({ role: ROLES.USER }),
          role: ROLES.USER,
          icon: ICONS.ROADMAP,
          key: 'product-roadmap',
        },
        {
          title: (i18n: I18n) => i18n._(t`Logout`),
          onClick: () => logout(),
          role: ROLES.USER,
          icon: ICONS.LOGOUT,
          key: 'logout',
        },
      ].filter((c) => c),
      get children() {
        return this._children;
      },
      set children(value) {
        this._children = value;
      },
    },
  ].filter((c) => c);
};

export default accountMenuStructure;
